import { Typography, withStyles } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from '../../../styles'
import { updatePosition } from '../services/categoriesApi'
import ButtonWithMenu from './ButtonWithMenu'

const SubCategory = ({ lastItem, setCategory, refreshCategories, index, setShowEditCategory, item, classes, setShowDeletePopin }) => {

	return (
		<div className={index % 2 === 0 ? classes.subcategoryWhite : classes.subcategoryGrey}>
			<div className={classes.headerCategory}>
				<div className={classes.labelSubCategory}>
					<Typography variant="h3">{item.label}</Typography>
				</div>
				<ButtonWithMenu
					type="secondary"
					aria-haspopup="true"
					variant="contained"
					items={[
						{
							label: <FormattedMessage id='gestion.category.modifier' />,
							onClick: (() => {
								setCategory(item)
								setShowEditCategory(true)
							})
						}, {
							label: <FormattedMessage id='gestion.category.supprimer' />,
							onClick: (() => {
								setCategory(item)
								setShowDeletePopin(true)
							})
						}
					]}>
					<Typography variant="h4"
					            className={[classes.pointer, classes.editSecondary, classes.editSubCategory, index % 2 === 0 ? classes.subcategoryWhite : classes.subcategoryGrey]}>
						<FormattedMessage id="category.edit" />
					</Typography>
				</ButtonWithMenu>
				<div className={classes.editPosition}>
					<KeyboardArrowUpIcon className={index !== 0 ? classes.iconBlack : classes.iconBlackDisabled} onClick={() => {
						if (index !== 0) {
							updatePosition({ idCategory: item.id, position: -1 }).then(() => {
								refreshCategories()
							})
						}
					}} />
					<KeyboardArrowDownIcon className={!lastItem ? classes.iconBlack : classes.iconBlackDisabled} onClick={() => {
						if (!lastItem) {
							updatePosition({ idCategory: item.id, position: 1 }).then(() => {
								refreshCategories()
							})
						}
					}} />
				</div>
			</div>
		</div>
	)
}

export default withStyles(styles)(SubCategory)