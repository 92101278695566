import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'redux'
import colors from '../config/colors'
import Actions from './Actions'

const styles = () => getStyles({
	dialogTitle: {
		color: 'white'
	},
	dialogContent: {
		paddingTop: 20
	}
})

const Popin = ({ open, actions, classes, title, content, onClose, ...otherProps }) => {
	return (
		<Dialog
			open={open}
			onExited={onClose}
			PaperProps={{
				style: {
					backgroundColor: colors.background
				}
			}}
			{...otherProps}
		>
			<DialogTitle className="dialogTitle">
				<div className={classes.dialogTitle}>
					{title}
				</div>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				{content}
			</DialogContent>
			<DialogActions>
				<Actions>
					{actions}
				</Actions>
			</DialogActions>
		</Dialog>
	)
}

Popin.propTypes = {
	title: PropTypes.node,
	content: PropTypes.node,
	actions: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element)
	]),
	classes: PropTypes.object,
	open: PropTypes.bool,
	onClose: PropTypes.func
}

export default compose(
	withStyles(styles)
)(Popin)

