import { withStyles } from '@material-ui/core'
import { FormInput, getStyles } from 'isotope-client'
import FormSelect from 'isotope-client/components/form/fields/FormSelect'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reset, Field, isPristine, reduxForm } from 'redux-form'
import Button from '../../../components/Button'
import Popin from '../../../components/Popin'
import { newCategory } from '../services/categoriesApi'
import MenuItem from '@material-ui/core/MenuItem'

const style = () => getStyles({
	switch: {
		marginBottom: 20
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.label && !values.label) {
		errors.label = { id: 'general.erreur.obligatoire' }
	}

	return errors
}

const NewSubCategoryPopin = ({ open, submitting, handleSubmit, refreshCategories, refreshCodes, onClose, clearForm, codes, parentId, typeCategory }) => {

	React.useEffect(()=>{

	},[])

	const submitCreation = (values) => {
		newCategory({...values, parentId: parentId, type:typeCategory}).then(()=>{
			refreshCategories()
			refreshCodes()
			closePopup()
		})
	}

	const closePopup = () => {
		clearForm()
		onClose()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={onClose}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={handleSubmit(submitCreation)}
		>
			<FormattedMessage id='general.btn.creer' />
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={"Ajouter un produit"}
		onClose={onClose}
		content={
			<form>
				<div>
					<Field
						name="code"
						label={"Code"}
						component={FormSelect}
					>
						{(codes || []).map(code =>
							<MenuItem
								key={code.code}
								value={code.code}
							>
								{code.label}
							</MenuItem>
						)}
					</Field>
					<Field
						name="label"
						label={"Nom"}
						component={FormInput}
					/>
				</div>
			</form>
		}
	/>
}

const mapStateToProps = (state) => ({

	submitting: isPristine("CREATE_SUBCATEGORY_FORM")(state)
})

const actions = {
	clearForm: () => dispatch => dispatch(reset("CREATE_SUBCATEGORY_FORM")),
}

export default compose(
	withStyles(style),
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: "CREATE_SUBCATEGORY_FORM",
		validate,
		enableReinitialize: true
	})
)(NewSubCategoryPopin)
