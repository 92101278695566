import {
	isAuthorized as isAuthorizedApi,
	Page,
	refresh as refreshApi,
	refreshToken as refreshTokenAction,
	storeLocalUser as storeLocalUserApi,
	UsersPage
} from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { replace } from 'connected-react-router'
import AccueilPage from '../modules/accueil/AccueilPage'
import PacksPage from '../modules/categories/PacksPage'
import ProductsPage from '../modules/categories/ProductsPage'


class App extends Component {


	componentWillMount() {
		const { user, location, logout, gotoLogin } = this.props
		if (!user.authenticated) {
			gotoLogin(location)
		} else {
			// Si le user est bien authentifié, je vérifie les droits
			isAuthorizedApi(location.pathname)
				.then(retour => {
					if (!retour) logout()
				})
		}

		this.refresh()
		setInterval(() => {
			this.refresh()
		}, 1700 * 1000)

	}

	componentWillReceiveProps(nextProps) {
		if (!nextProps.user.authenticated) {
			this.props.gotoLogin()
		}
	}


	refresh() {
		if (this.props.user && this.props.user.token) {
			const refreshAction = this.props.refreshAction
			const token = localStorage.getItem('token')
			refreshApi(token).then((json) => {
				if (json) {
					refreshAction(json.token)
					storeLocalUserApi(json)
				}
			})
		}
	}


	render() {
		const { location } = this.props
		return (
			<Page location={location}>
				<Switch>
					<Route exact path="/" component={AccueilPage} />
					<Route exact path="/vaps" component={ProductsPage} />
					<Route exact path="/pack" component={PacksPage} />
					<Route path="/utilisateurs" component={UsersPage} />
				</Switch>
			</Page>
		)
	}

}

App.propTypes = {
	location: PropTypes.object.isRequired,
	gotoLogin: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	refreshAction: PropTypes.func.isRequired,
	user: PropTypes.object
}

// TODO mettre un selector ici
const mapStateToProps = (state) => ({
	user: state.user
})

// TODO Refaire les actions en mode propre
const mapDispatchToProps = (dispatch) => ({
	refreshAction: (token) => dispatch(refreshTokenAction(token)),
	gotoLogin: (location) => dispatch(replace({
		pathname: '/login',
		state: {
			nextPathname: location.pathname
		}
	})),
	logout: () => console.log('logout')
})


export default connect(mapStateToProps, mapDispatchToProps)(App)
