import { fetchFactory } from 'isotope-client'

export const fetchProducts = () => fetchFactory(`/categories/products`)
export const fetchPacks = () => fetchFactory(`/categories/packs`)
export const fetchCodeProducts = () => fetchFactory(`/categories/codes`)

export const newCategory = (values) => fetchFactory('/categories', {
	method: 'POST',
	body: JSON.stringify(values)
})

export const deleteCategory = (id) => fetchFactory(`/categories/${id}`, {
	method: 'DELETE'
})

export const updatePosition = (values) => fetchFactory('/categories/position', {
	method: 'POST',
	body: JSON.stringify(values)
})