import { Collapse, Typography, withStyles } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TYPE_PACK, TYPE_PRODUIT } from '../../../constants'
import styles from '../../../styles'
import AddCategory from './AddCategory'
import ButtonWithMenu from './ButtonWithMenu'
import SubCategory from './SubCategory'
import { updatePosition } from '../services/categoriesApi'

const Category = ({ type, item, index, lastItem, setCategory, subcategories, setShowModalSubCategory, setShowEditCategory, refreshCategories, classes, setParentIdPopin, setShowDeletePopin }) => {

	const [collapse, setCollapse] = React.useState(false)

	return (
		<div className={classes.category}>
			<div className={classes.headerCategory}>
				{type===TYPE_PRODUIT && collapse && <KeyboardArrowDownIcon className={classes.icon} onClick={() => {
					setCollapse(!collapse)
				}} />}
				{type===TYPE_PRODUIT && !collapse && <KeyboardArrowRightIcon className={classes.icon} onClick={() => {
					setCollapse(!collapse)
				}} />}
				<div className={classes.labelCategory} onClick={() => setCollapse(!collapse)}>
					<Typography variant="h1" className={classes.label}>{item.label}</Typography>
				</div>
				{<ButtonWithMenu
					type="primary"
					aria-haspopup="true"
					variant="contained"
					items={type === TYPE_PRODUIT ? [
						{
							label: <FormattedMessage id='gestion.category.modifier' />,
							onClick: (() => {
								setCategory(item)
								setShowEditCategory(true)
							})
						}, {
							label: <FormattedMessage id='gestion.category.supprimer' />,
							onClick: (() => {
								setCategory(item)
								setShowDeletePopin(true)
							})
						}
					] : [{
						label: <FormattedMessage id='gestion.category.modifier' />,
						onClick: (() => {
							setCategory(item)
							setShowEditCategory(true)
						})
					}]}>
					<Typography variant="h2" className={[classes.pointer, classes.editPrimary, classes.editCategory]}><FormattedMessage id="category.edit" />
					</Typography>
				</ButtonWithMenu>}
				{type === TYPE_PRODUIT && <div className={classes.editPosition}>
					<KeyboardArrowUpIcon className={index !== 0 ? classes.icon : classes.iconDisabled} onClick={() => {
						if (index !== 0) {
							updatePosition({ idCategory: item.id, position: -1 }).then(() => {
								refreshCategories()
							})
						}
					}} />
					<KeyboardArrowDownIcon className={!lastItem ? classes.icon : classes.iconDisabled} onClick={() => {
						if (!lastItem) {
							updatePosition({ idCategory: item.id, position: 1 }).then(() => {
								refreshCategories()
							})
						}
					}} />
				</div>}
			</div>
			{type===TYPE_PRODUIT && <Collapse children={<div className={classes.collapseBorder}>
				{subcategories && subcategories.length > 0 && subcategories.map((item, index) =>
					<SubCategory lastItem={index === subcategories.length - 1} refreshCategories={refreshCategories} index={index} setCategory={setCategory}
					             setShowEditCategory={setShowEditCategory}
					             setShowDeletePopin={setShowDeletePopin} item={item} />
				)}
				<AddCategory category={false} title={'AJOUTER UN NOUVEAU PRODUIT'}
				             index={subcategories && subcategories.length > 0 ? subcategories.length : 0} item={item}
				             setShowModal={setShowModalSubCategory} setParentIdPopin={setParentIdPopin} />
			</div>
			} in={collapse} />}
			{type === TYPE_PACK && <div className={classes.collapseBorder}>
				{subcategories && subcategories.length > 0 && subcategories.map((item, index) =>
					<SubCategory lastItem={index === subcategories.length - 1} refreshCategories={refreshCategories} index={index} setCategory={setCategory}
					             setShowEditCategory={setShowEditCategory}
					             setShowDeletePopin={setShowDeletePopin} item={item} />
				)}
				<AddCategory category={false} title={'AJOUTER UN NOUVEAU PACK'}
				             index={subcategories && subcategories.length > 0 ? subcategories.length : 0} item={item}
				             setShowModal={setShowModalSubCategory} setParentIdPopin={setParentIdPopin} />
			</div>}
		</div>
	)
}

export default withStyles(styles)(Category)