const colors = {
	primary: '#3a9618',
	secondary: '#00F599',
	tertiary: '#FFDD00',
	border: '#0000001F',
	error: '#F44336',
	background: '#FFFFFF',
	gray:'#f7f7f7',
	text: '#000000',
	dialogBackground: '#F3F3F3'
}

export default colors
