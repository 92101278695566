import { Typography, Grid, withStyles } from '@material-ui/core'
import React from 'react'
import styles from '../../styles'

const AccueilPage = ({ classes }) => (
	<Grid container>
		<Grid item>
			<Typography></Typography>
		</Grid>
	</Grid>
)

export default withStyles(styles)(AccueilPage)