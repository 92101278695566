import { withStyles } from '@material-ui/core'
import { getStyles } from 'isotope-client'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'redux'
import Button from '../../../components/Button'
import Popin from '../../../components/Popin'
import { deleteCategory } from '../services/categoriesApi'

const style = () => getStyles({
	switch: {
		marginBottom: 20
	}
})

const DeleteCategoryPopin = ({ classes, open, submitting, intl, refreshCategories, refreshCodes, onClose, category }) => {

	const deleteCat = (category) => {
		deleteCategory(category.id).then(() => {
			refreshCategories()
			refreshCodes()
			closePopup()
		})
	}

	let title = ''
	console.log(category,"category")
	if (category && category.parentId && category.type==="2") {
		title = 'Suppression d\'un pack'
	} else if (category && category.parentId && category.type==="1") {
		title = 'Suppression d\'un produit'
	} else {
		title = 'Suppression d\'une catégorie'
	}

	const closePopup = () => {
		onClose()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={onClose}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={() => {
				deleteCat(category)
			}}
		>
			<FormattedMessage id='general.btn.confirm' />
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={title}
		onClose={onClose}
		content={category && category.parentId && category.type ==="2" ? <FormattedMessage id="gestion.category.deletePack" values={{ string: category && category.label }} /> :
			category && category.parentId && category.type ==="1" ? <FormattedMessage id="gestion.category.deleteProduct" values={{ string: category && category.label }} /> :
				<FormattedMessage id="gestion.category.deleteCategory" values={{ string: category && category.label }} />}
	/>
}

export default compose(
	withStyles(style),
	injectIntl
)(DeleteCategoryPopin)
