import { Typography, withStyles } from '@material-ui/core'
import React from 'react'
import styles from '../../../styles'

const AddCategory = ({ category, index, setShowModal, item, classes, setParentIdPopin, title }) => {

	return (
		<div className={index %2 === 0 ? classes.subcategoryWhite : classes.subcategoryGrey} onClick={() => {
			setShowModal(true)
			if(item && item.id) {
				setParentIdPopin(item.id)
			}
		}}>
			{category ?
				<div className={classes.addCategory}>
					<Typography variant="h1" className={[classes.label, classes.pointer]}>{title}</Typography>
				</div> :
				<div className={classes.addSubCategory}>
					<Typography variant="h4" className={[classes.label, classes.pointer]}>{title}</Typography>
				</div>
			}
		</div>
	)
}

export default withStyles(styles)(AddCategory)