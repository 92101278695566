import colors from './config/colors'

const styles = {
	accueilPaper: {
		padding: 10
	},
	category: {
		backgroundColor:colors.primary,
		marginBottom:30
	},
	subcategoryWhite: {
		backgroundColor:colors.background
	},
	subcategoryGrey: {
		backgroundColor:colors.gray,
	},
	labelCategory: {
		flex:1,
		paddingLeft:16,
		cursor:'pointer'
	},
	editPrimary: {
		backgroundColor: colors.primary,
		color: colors.background
	},
	editSecondary: {
		textColor: colors.primary
	},
	labelSubCategory: {
		flex:1,
		paddingLeft:16
	},
	icon: {
		color:colors.background,
		cursor:'pointer'
	},
	iconDisabled: {
		color:colors.background,
		opacity:0.3
	},
	iconBlack: {
		color:colors.text,
		cursor:'pointer'
	},
	iconBlackDisabled: {
		color:colors.text,
		opacity:0.3
	},
	editCategory: {
		paddingRight:20,
		paddingLeft:20,
		paddingBottom:5,
		paddingTop:5,
		marginRight:26,
		border:'2px solid white',
		borderRadius: 5
	},
	pointer: {
		cursor:'pointer'
	},
	editSubCategory: {
		paddingRight:20,
		paddingLeft:20,
		paddingBottom:5,
		paddingTop:5,
		marginRight:26,
		border:'2px solid',
		borderRadius: 5,
		borderColor:colors.primary
	},
	editPosition: {
		flexDirection: 'column',
		display:'flex'
	},
	headerCategory: {
		display:'flex',
		flexDirection:'row',
		alignItems:'center',
		paddingBottom:15,
		paddingLeft:26,
		paddingRight:26,
		paddingTop:15
	},
	addCategory: {
		display:'flex',
		backgroundColor: colors.primary,
		flexDirection:'row',
		alignItems:'center',
		paddingBottom:15,
		paddingLeft:26,
		paddingRight:26,
		paddingTop:15,
		justifyContent: 'center'
	},
	addSubCategory: {
		display:'flex',
		flexDirection:'row',
		alignItems:'center',
		paddingBottom:15,
		paddingLeft:26,
		paddingRight:26,
		paddingTop:15,
		justifyContent: 'center'
	},
	safeArea: {
		paddingTop:55,
		paddingLeft:96,
		paddingRight:96,
		alignItems:'center',
		justifyContent:'center',
		flex:1,
	},
	collapseBorder: {
		border: '2px solid',
		borderColor: colors.gray
	}
}

export default styles