import { getStyles } from 'isotope-client'
import React from 'react'
import Menu from '@material-ui/core/Menu'
import Fade from '@material-ui/core/Fade'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = (theme) => getStyles({
	icon: {
		color: theme.palette.text.main,
		minWidth: 35
	}
})

const ButtonWithMenu = ({ type, items, classes, children, ...otherProps }) => {

	const [anchorEl, setAnchorEl] = React.useState(null)

	const handleClick = (event) => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	const withIcons = items.some(item => !!item.icon)

	return (
		<div className={classes.editCategory}>
			<div {...otherProps} onClick={handleClick}>
				{children}
			</div>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				{items.map((item, index) =>
					<MenuItem
						onClick={(event) => {
							if (typeof item.onClick === 'function') {
								item.onClick(event)
							}
							handleClose()
						}}
						disabled={item.disabled}
					>
						{withIcons && <ListItemIcon className={classes.icon}>
							{item.icon}
						</ListItemIcon>}
						<div >
						{item.label}
						</div>
					</MenuItem>
				)}
			</Menu>
		</div>
	)
}

export default withStyles(styles)(ButtonWithMenu)
