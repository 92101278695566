import { withStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { FormInput, getStyles } from 'isotope-client'
import FormSelect from 'isotope-client/components/form/fields/FormSelect'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, isPristine, reduxForm, reset } from 'redux-form'
import Button from '../../../components/Button'
import Popin from '../../../components/Popin'
import { newCategory } from '../services/categoriesApi'

const style = () => getStyles({
	switch: {
		marginBottom: 20
	}
})

const validate = (values) => {
	const errors = {}

	if (!values.label && !values.label) {
		errors.label = { id: 'general.erreur.obligatoire' }
	}

	return errors
}

const EditCategoryPopin = ({ open, submitting, handleSubmit, onClose, change, refreshCategories, refreshCodes, clearForm, codes, parentId, typeCategory, category }) => {

	let title = ""
	if(category && category.parentId && category.type==="2"){
		title="Editer un pack"
	} else if(category && category.parentId && category.type==="1"){
		title="Editer un produit"
	} else {
		title="Editer une catégorie"
	}

	React.useEffect(() => {
		if (category) {
			change('code', category.codeCategory)
			change('label', category.label)
		}
	}, [category])

	const submitCreation = (values) => {
		newCategory({ ...values, parentId: parentId, type: typeCategory }).then(() => {
			refreshCategories()
			refreshCodes()
			closePopup()
		})
	}

	const closePopup = () => {
		clearForm()
		onClose()
	}

	const actions = [
		<Button
			key="close"
			color="primary"
			onClick={onClose}
		>
			<FormattedMessage id="general.btn.annuler" />
		</Button>,
		<Button
			key="save"
			color="primary"
			variant="contained"
			loading={submitting}
			onClick={handleSubmit(submitCreation)}
		>
			<FormattedMessage id='general.btn.modifier' />
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={title}
			onClose={onClose}
			content={
			<form>
			<div>
			<Field
			name="code"
			label={'Code'}
			component={FormSelect}
			disabled
			>
			{(codes || []).map(code =>
				<MenuItem
					key={code.code}
					value={code.code}
				>
					{code.label}
				</MenuItem>
			)}
			</Field>
			<Field
			name="label"
			label={'Nom'}
			component={FormInput}
			/>
			</div>
			</form>
		}
			/>
		}
		const mapStateToProps = (state) => ({
	submitting: isPristine('EDIT_CATEGORY_FORM')(state)
})

const actions = {
	clearForm: () => dispatch => dispatch(reset('EDIT_CATEGORY_FORM'))
}

export default compose(
	withStyles(style),
	connect(mapStateToProps, actions),
	injectIntl,
	reduxForm({
		form: 'EDIT_CATEGORY_FORM',
		validate,
		enableReinitialize: true
	})
)(EditCategoryPopin)
