import { withStyles } from '@material-ui/core'
import React from 'react'
import ErrorPopin from '../../components/ErrorPopin'
import { TYPE_PRODUIT } from '../../constants'
import styles from '../../styles'
import AddCategory from './components/AddCategory'
import Category from './components/Category'
import DeleteCategoryPopin from './components/DeleteCategoryPopin'
import EditCategoryPopin from './components/EditCategoryPopin'
import NewCategoryPopin from './components/NewCategoryPopin'
import NewSubCategoryPopin from './components/NewSubCategoryPopin'
import { fetchCodeProducts, fetchProducts } from './services/categoriesApi'

const ProductsPage = ({ classes }) => {

	const [showModalCategory, setShowModalCategory] = React.useState(false)
	const [showModalSubCategory, setShowModalSubCategory] = React.useState(false)
	const [showEditCategory, setShowEditCategory] = React.useState(false)
	const [openErrorPopup, setOpenErrorPopup] = React.useState(false)
	const [showDeletePopin, setShowDeletePopin] = React.useState(false)
	const [error, setError] = React.useState(false)
	const [codes, setCodes] = React.useState([])
	const [parentIdPopin, setParentIdPopin] = React.useState(null)
	const [category, setCategory] = React.useState(null)

	const [products, setProducts] = React.useState([])

	React.useEffect(() => {
		fetchProducts().then(setProducts)
	}, [])

	const refreshCategories = () => {
		fetchProducts().then(setProducts)
	}

	const refreshCodes = () => {
		fetchCodeProducts().then(setCodes)
	}

	React.useEffect(() => {
		fetchCodeProducts().then(setCodes)
	}, [])

	return (
		<div className={classes.safeArea}>
			{products.map((item,index) =>
				<Category type={TYPE_PRODUIT} lastItem={index === products.length - 1} index={index} refreshCategories={refreshCategories} setShowDeletePopin={setShowDeletePopin} setCategory={setCategory} item={item.category} subcategories={item.children} setShowEditCategory={setShowEditCategory} setShowModalSubCategory={setShowModalSubCategory} setParentIdPopin={setParentIdPopin} />
			)}
			<AddCategory category title={'Ajouter une nouvelle catégorie'} setShowModal={setShowModalCategory}/>
			<NewCategoryPopin
				codes={codes}
				open={showModalCategory}
				refreshCategories={refreshCategories}
				refreshCodes={refreshCodes}
				typeCategory={TYPE_PRODUIT}
				onClose={() => {
					setShowModalCategory(false)
				}}
				onError={(e) =>{
					setError(e)
					setOpenErrorPopup(true)
					setShowModalCategory(false)
				}
				} />
			<NewSubCategoryPopin
				codes={codes}
				open={showModalSubCategory}
				refreshCategories={refreshCategories}
				refreshCodes={refreshCodes}
				parentId={parentIdPopin}
				typeCategory={TYPE_PRODUIT}
				onClose={() => {
					setShowModalSubCategory(false)
				}}
				onError={(e) =>{
					setError(e)
					setOpenErrorPopup(true)
					setShowModalSubCategory(false)
				}
				} />
			<EditCategoryPopin
				codes={codes}
				open={showEditCategory}
				refreshCategories={refreshCategories}
				refreshCodes={refreshCodes}
				category={category}
				typeCategory={TYPE_PRODUIT}
				onClose={() => {
					setShowEditCategory(false)
				}}
				onError={(e) =>{
					setError(e)
					setOpenErrorPopup(true)
					setShowEditCategory(false)
				}
				} />
			<ErrorPopin
				open={openErrorPopup}
				onClose={() => setOpenErrorPopup(false)}
				errorTitle={error && error.title}
				errorMessage={error && error.message}
			/>
			<DeleteCategoryPopin
				category={category}
				open={showDeletePopin}
				refreshCategories={refreshCategories}
				refreshCodes={refreshCodes}
				onClose={() => setShowDeletePopin(false)}
				onError={(e) =>{
					setError(e)
					setOpenErrorPopup(true)
					setShowDeletePopin(false)
				}}
			/>
		</div>
	)
}

export default withStyles(styles)(ProductsPage)