import { withStyles } from '@material-ui/core'
import React from 'react'
import ErrorPopin from '../../components/ErrorPopin'
import { TYPE_PACK } from '../../constants'
import styles from '../../styles'
import Category from './components/Category'
import DeleteCategoryPopin from './components/DeleteCategoryPopin'
import EditCategoryPopin from './components/EditCategoryPopin'
import NewSubCategoryPopin from './components/NewSubCategoryPopin'
import { fetchCodeProducts, fetchPacks } from './services/categoriesApi'

const PacksPage = ({ classes }) => {
	const [showModalSubCategory, setShowModalSubCategory] = React.useState(false)
	const [showEditCategory, setShowEditCategory] = React.useState(false)
	const [openErrorPopup, setOpenErrorPopup] = React.useState(false)
	const [showDeletePopin, setShowDeletePopin] = React.useState(false)
	const [error, setError] = React.useState(false)
	const [codes, setCodes] = React.useState([])
	const [parentIdPopin, setParentIdPopin] = React.useState(null)
	const [category, setCategory] = React.useState(null)

	const [packs, setPacks] = React.useState([])

	React.useEffect(() => {
		fetchPacks().then(setPacks)
	}, [])

	const refreshCategories = () => {
		fetchPacks().then(setPacks)
	}

	const refreshCodes = () => {
		fetchCodeProducts().then(setCodes)
	}

	React.useEffect(() => {
		fetchCodeProducts().then(setCodes)
	}, [])

	return (
		<div className={classes.safeArea}>
			{packs.map((item, index) =>
				<Category type={TYPE_PACK} lastItem={index === packs.length - 1} index={index} refreshCategories={refreshCategories} setShowDeletePopin={setShowDeletePopin} setCategory={setCategory} item={item.category} subcategories={item.children}
				          setShowEditCategory={setShowEditCategory} setShowModalSubCategory={setShowModalSubCategory} setParentIdPopin={setParentIdPopin} />
			)}
			<NewSubCategoryPopin
				codes={codes}
				open={showModalSubCategory}
				refreshCategories={refreshCategories}
				refreshCodes={refreshCodes}
				parentId={parentIdPopin}
				typeCategory={TYPE_PACK}
				onClose={() => {
					setShowModalSubCategory(false)
				}}
				onError={(e) => {
					setError(e)
					setOpenErrorPopup(true)
					setShowModalSubCategory(false)
				}
				} />
			<EditCategoryPopin
				codes={codes}
				open={showEditCategory}
				refreshCategories={refreshCategories}
				refreshCodes={refreshCodes}
				category={category}
				typeCategory={TYPE_PACK}
				onClose={() => {
					setShowEditCategory(false)
				}}
				onError={(e) => {
					setError(e)
					setOpenErrorPopup(true)
					setShowEditCategory(false)
				}
				} />
			<ErrorPopin
				open={openErrorPopup}
				onClose={() => setOpenErrorPopup(false)}
				errorTitle={error && error.title}
				errorMessage={error && error.message}
			/>
			<DeleteCategoryPopin
				category={category}
				open={showDeletePopin}
				refreshCategories={refreshCategories}
				refreshCodes={refreshCodes}
				onClose={() => setShowDeletePopin(false)}
				onError={(e) => {
					setError(e)
					setOpenErrorPopup(true)
					setShowDeletePopin(false)
				}}
			/>
		</div>
	)
}

export default withStyles(styles)(PacksPage)
