import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { compose } from 'redux'
import Button from './Button'
import Popin from './Popin'

const ErrorPopin = ({ open, intl, onClose, errorMessage, errorTitle }) => {

	const actions = [
		<Button
			key="close"
			color="primary"
			variant="contained"
			onClick={onClose}
		>
			<FormattedMessage id="general.btn.ok" />
		</Button>
	]

	return <Popin
		open={open}
		actions={actions}
		title={errorTitle || intl.formatMessage({ id: 'error.technique.title' })}
		onClose={onClose}
		content={errorMessage || intl.formatMessage({ id: 'error.technique.message' })}
	/>
}

ErrorPopin.propTypes = {
	open: PropTypes.bool,
	intl: PropTypes.object,
	onClose: PropTypes.func,
	errorMessage: PropTypes.string,
	errorTitle: PropTypes.string
}

export default compose(
	injectIntl
)(ErrorPopin)
