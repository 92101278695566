import { MuiThemeProvider } from '@material-ui/core'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import createHistory from 'history/createBrowserHistory'
import { createIsotopeTheme, fetchUser as fetchUserApi, messages as messagesIsotope } from 'isotope-client'
import React from 'react'
import ReactDOM from 'react-dom'
import { addLocaleData } from 'react-intl'
import { intlReducer, Provider } from 'react-intl-redux'
import fr from 'react-intl/locale-data/fr'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import colors from './config/colors'
import messages from './config/messages'
import reducers from './config/reducers'
import Root from './containers/Root'
import './index.css'

const theme = createIsotopeTheme({
	login: {
		background: 'url(\'img/connexion.png\')'
	},
	typography: {
		headline: {
			fontSize: 20,
			fontWeight: 700,
			marginBottom: 20
		},
		subheading: {
			fontSize: 15
		},
		h6: {
			fontSize: 16
		},
		h1: {
			color: colors.background,
			fontSize: 21,
			fontWeight: 600
		},
		h2: {
			color: colors.background,
			fontSize: 18
		},
		h4: {
			color: colors.primary,
			fontSize: 18
		},
		h3: {
			color: colors.text,
			fontSize: 14
		},
		body1: {
			fontSize: 14,
			color: colors.text
		}
	},
	palette: {
		primary: {
			main: colors.primary,
			contrastText: '#FFFFFF'
		}
	},
	overrides: {
		MuiDialogTitle: {
			root: {
				padding: '15px 22px',
				color: 'white',
				backgroundColor: colors.primary
			}
		},
		MuiDialogContent: {
			root: {
				padding: '15px 25px',
				backgroundColor: colors.dialogBackground,
				fontSize: 14
			}
		},
		MuiDialogActions: {
			root: {
				backgroundColor: colors.dialogBackground
			}
		},
		MuiFormLabel: {
			root: {
				marginBottom: 8,
				fontWeight: 600
			}
		},
		MuiTableCell: {
			body: {
				fontSize: 14,
				color: colors.text
			}
		},
		MuiMenuItem: {
			root: {
				'&$selected': {
					backgroundColor: colors.border
				}
			},
			selected: {}
		},
		MuiIconButton: {
			root: {
				padding: 0
			}
		},
		MuiListItemText: {
			inset: {
				paddingLeft: 10
			}
		}
	}
})

// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login
const reactInit = (user) => {

	// Initialisation de la partie i18n (react-intl)
	addLocaleData(fr)
	const formats = {
		date: {
			datetime: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			}
		}
	}

	// Create a history of your choosing (we're using a browser history in this case)
	const history = createHistory()

	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]

	// En mode dév, on utilise le devtools
	if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	}

	// Création du store de redux
	let store = createStore(
		combineReducers({
			...reducers,
			router: connectRouter(history),
			intl: intlReducer,
			form: formReducer
		}), {
			user,
			intl: {
				locale: 'fr',
				messages: {
					...messages,
					...messagesIsotope
				},
				formats: {
					...formats
				},
				defaultLocale: 'fr',
				defaultFormats: {
					...formats
				}
			}
		},
		compose(...enhancers)
	)

	ReactDOM.render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<MuiThemeProvider theme={theme}>
					<Root />
				</MuiThemeProvider>
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root')
	)
}

const token = window.localStorage.getItem('token')
if (token) {
	fetchUserApi(token).then(user => {
		if (user) {
			user.token = token
			user.authenticated = true
		}
		reactInit(user)
	}).catch((error) => {
		console.log(error)
		reactInit()
	})
} else {
	reactInit()
}

// Webpack Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./containers/Root', () => {
		reactInit()
	})
}

// TODO à voir si on garde ça
// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();
